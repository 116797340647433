<template>
<div >

        <div class=" mb-3">
           <span class="h4 titulosg">Permisos</span><br>
           Configuración de los persmisos de acceso.
        </div>

        <div class="mb-3">
            <button @click="CrearEditarPermiso('n','nuevo')" type="button" class="btn btn-primary"><i class="fas fa-plus-circle me-2"></i> Agregar Permiso</button>
        </div>

        <div class="row">
            <div class="col-md-12 mb-3" v-for="permiso in permisos.filter(permisos => permisos.permisos_idpermisos === null)" :key="permiso.idpermisos" >
                <div class="card">
                        <div class="pt-3 pb-3 ps-4 row">
                            <div class="col-md-11">
                                <a :href="'#p'+permiso.idpermisos" role="button"  data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">                             
                                    <span v-html="permiso.icono" class="me-2"></span>  <strong class="titulosg">{{ permiso.nombrepermiso }}</strong>   <i class="fas fa-sort-down me-5"></i> 
                                </a>
                                <br>
                                <span class="text-black-50"> {{permiso.descripcion}} </span>
                            </div>
                            <div class="col-md-1">
                                <a @click="CrearEditarPermiso(permiso.idpermisos,'Editar')" class="btn btn-sm btn-outline-primary border-0 "> <i class="far fa-edit"></i> Editar </a>
                            </div>
                        </div>

                        <div class="collapse" :id="'p'+permiso.idpermisos" >
                            <div class="table-responsive">
                            <table class="table  table-hover mb-0">
                                <tbody>
                                    <tr v-for="(sub, index) in subpermisos.filter(permisos => permisos.permisos_idpermisos === permiso.idpermisos)" :key="sub.idpermisos" >
                                        <td style="width: 23px;"> <div class="bg-light ms-2 mt-2 text-secondary p-1 text-center rounded-circle" style="width: 25px; font-size: 12px; margin: auto;">{{sub.orden}}</div> </td>
                                        <td style="width: 35%;" class="ps-3" > 
                                            <span v-html="sub.icono" class=" text-dark me-2"></span> {{ sub.nombrepermiso }}  
                                            <div class="text-black-50 mb-2">{{ sub.descripcion }}</div>                                                                                                                                    
                                        </td>
                                        <td><span style="font-size: 11px">Asociados a:</span> <br> <span class="badge me-1 bg-light text-secondary" v-for="item in sub.permisosxtipopersona" :key="item.idpersonascategoria"> {{item.nombrecategoria}}   </span></td>
                                        <td><span style="font-size: 11px">Link:</span> <br> {{ sub.linkpermiso }}</td>
                                        <td style="width:10px;"> <a @click="CrearEditarPermiso(sub.idpermisos,'Editar')" title="Editar" class="btn btn-sm btn-outline-primary border-0"> <i class="far fa-edit"></i>  Editar</a></td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            </div>
                        </div>

                </div>
            </div>
        </div>
</div>

</template>
<script>

    import { ref, onMounted } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import axios from "axios"

    export default {
        components: {

        },
        setup(props) {

            const router = useRouter()
            const route = useRoute()

            const infopermiso = ref([])
            const permisos = ref([])
            const subpermisos = ref([])
            
            const idpermiso = ref()

            const listarpermisos =  () => {
                axios.get('/api/permisos').then((response) => {
                    permisos.value = response.data
                    subpermisos.value = response.data
                });
            }

            const respuestacomponente = (id) => {
                listarpermisos();
            }

            const CrearEditarPermiso = (idpermiso, accionrecibida) => {
                router.push({ name: 'CrearEditarPermiso', params: { id: idpermiso,  accion: accionrecibida } })
            }

 
            onMounted(() => {
                listarpermisos();
                
            })


            return {
                infopermiso,
                permisos,
                subpermisos,
                idpermiso,

                //Funciones
                CrearEditarPermiso

            } 
        }
 
    }
</script>